<template>
    <div>
        数据迁移
    </div>
</template>

<script>
    export default {
        name: "dataMigration"
    }
</script>

<style scoped>

</style>
